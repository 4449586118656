import { DefaultValue, selector, selectorFamily } from "recoil";

import {
  dashboardBizStatus,
  dashboardBizValue, dashboardEditActiveState, dashboardErrorStatus, dashboardEsrrRegion,
  // dashboardGridState,
  dashboardGridKeyState, dashboardMapErrorStatus, dashboardOverallRegion, dashboardRecoilClearState, dashboardRefreshState, dashboardRegionStatus,
  dashboardRegionValue, dashboardSolarRegion,
  // dashboardGridNameState,
  // dashboardPropState,
  gridLayoutSettingProcessState
} from "../atoms";

import axios from "axios";
import dayjs from "dayjs";
import { DASHBOARD_BJDONG_CD_ALL, EventLvl } from "src/common/CodeConstants";
import { getBjdongCd } from "src/common/Utils";


/////////////대시보드///////////////////////////////////////

/**
 * 대시보드 위젯 조회
 */
 export const getDashboardWidgetList = selector({
	key : 'getDashboardWidgetList',
	get : async ({ get }) => {
		const response = await axios.get('/dashboards/widget').catch((err)=>{throw err});

    return response.data;
	}
});

/**
 * 대시보드 조회
 */
 export const getDashboardList = selector({
	key : 'getDashboardList',
	get : async ({ get }) => {
		const response = await axios.get('/dashboards').catch((err)=>{throw err});
    return response.data;
	},
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent'
  }, 
  dangerouslyAllowMutability: true
});

/**
 * 그리드 위젯 리스트
 */
export const getGridItemList = selector({
  key: 'getGridItemList',
  get: async ({ get })=> {
    // const dashboardProp = get(dashboardPropState);
    // const { dashboardKey, dashboardName, editableYn, rpstvYn } = dashboardProp;
    const dashboardKey = get(dashboardGridKeyState);

    // let gridName = get(dashboardGridNameState);
    // let {dashboardGridKey, dashboardGridName} = get(dashboardGridState);
    let gridLayoutList = [];
    if(dashboardKey == null) return gridLayoutList; //test

    
 

    //임시 처리 test
    const response = await axios.get('/dashboards/property' + "?dsSeq=" + dashboardKey ).catch((err)=>{throw err});
    

    let responseDataList = response.data;
    for(let i in responseDataList){
      let item = responseDataList[i];
      let newGridItem = {
        static:false,
        // i: (layout.length).toString(),
        i: dashboardKey + "_" +i,
        x: item.xaxis,
        y: item.yaxis == null? Infinity : item.yaxis,
        w: item.width,
        h: item.height,
        seq: item.dsWigtSeq,
        type: item.dsWigtType,
        name: item.dsWigtNm,
        headerDisabled:item.dsWigtHeaderYn == 'Y'?false:true
      }

      gridLayoutList.push(newGridItem);
      
    }

    return gridLayoutList;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent'
  },
   dangerouslyAllowMutability: true
});


export const gridLayoutSettingProcessFilterState = selector({
  key: "gridLayoutSettingProcessFilterState",
  get: ({ get }) => {

    let gridLayoutSettingProcess = get(gridLayoutSettingProcessState); //변경된 레이어 데이터
    let lookUpLayout = get(getGridItemList); // 초기 레이아웃 데이터
    let settingLayout = gridLayoutSettingProcess.layout; //변경된 레이어 데이터
    let changeType = gridLayoutSettingProcess.changeType; //현재 시점의 레이아웃 변경 타입(과정)
    let newGridLayoutSettingProcess = null; //새로 반환할 레이아웃 프로세스 데이터

    let resultLayout = []; // 반환할 레이아웃 데이터 리스트

    if(changeType == null){// 그리드 하위 위젯 조회 후 초기세팅 (데이터가 변경중이 아닌 상태)
      const editLayout = lookUpLayout.map(item => { //static 값이 false인 새 리스트로 복사
        let newItem = Object.assign({},item,{static:false});
        return newItem;
      });

      resultLayout = editLayout;
    } else { // 변경된 레이아웃 데이터가 존재하면(초기세팅시에는 데이터 없음)
      resultLayout = settingLayout;
    }

    newGridLayoutSettingProcess = Object.assign({},gridLayoutSettingProcess,{layout: resultLayout})
    return newGridLayoutSettingProcess;
  },
  set: ({ set, reset }, newValue) => {
    if(newValue instanceof DefaultValue){
      reset(gridLayoutSettingProcessState);
    } else {
      set(gridLayoutSettingProcessState, newValue);
    }
  },
  dangerouslyAllowMutability: true

});

///////////////////////////////////////////////////////////

////////////////대시보드 공통///////////////////
export const dashboardRecoilClearFilterState = selector({
  key: 'dashboardRecoilClearFilterState',
  get: ({ get }) => {
    let value = get(dashboardRecoilClearState);
     return value;
  },
  set: ({ set, reset }, newValue) => {
    reset(dashboardRecoilClearState);

    if(newValue instanceof DefaultValue){
      return;
    }else{
      switch (newValue) {
        // case 'SET':
        //   // reset(settingLayoutSideLayoutOpenState);
        //   // reset(settingLayoutChangeTypeState);
        //   reset(gridLayoutSettingProcessState);
        //   // reset(settingLayoutNewItemCounterState);
        //   break;
  

        case 'STATIC':
          reset(dashboardGridKeyState);
          reset(dashboardEditActiveState);
          reset(gridLayoutSettingProcessState);
          reset(dashboardRefreshState);
          reset(dashboardErrorStatus);
          reset(dashboardRegionStatus);
          reset(dashboardRegionValue);
          reset(dashboardBizStatus);
          reset(dashboardBizValue);
          reset(dashboardOverallRegion);
          reset(dashboardEsrrRegion);
          reset(dashboardSolarRegion);
          reset(dashboardMapErrorStatus);
          break;
  
        default:
          break;
      }
    }
  }
});

/**
 * 대시보드 속성 값 readOnly
 */
export const dashboardPropFilterState = selector({
  key: 'dashboardPropFilterState',
  get: async ({ get })=> {


    const dashboardKey = get(dashboardGridKeyState);

    let resource = {
      dashboardKey: dashboardKey,
      dashboardName: null,
      editableYn: null,
      shereYn: null,
      rpstvYn: null,
      ownerId:null
    }

    if(dashboardKey == null) return resource;


    const response = await axios.get('/dashboards/' + dashboardKey ).catch((err)=>{throw err});
    const {dsNm, editableYn, shereYn, rpstvYn, ownerId } = response.data.data.dashboard;

    resource.dashboardKey = dashboardKey;
    resource.dashboardName = dsNm;
    resource.editableYn = editableYn;
    resource.shereYn = shereYn;
    resource.rpstvYn = rpstvYn;
    resource.ownerId = ownerId;
    
    return resource;
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent'
  },
   dangerouslyAllowMutability: true
});

/**
 * 대시보드 속성 값 ( 대시보드 수정용 )
 */
 export const dashboardPropFormFilterState = selector({
  key: 'dashboardPropFormFilterState',
  get: async ({ get })=> {
    const dashboardProp = get(dashboardPropFilterState);
    const { dashboardKey, dashboardName, editableYn, shereYn, rpstvYn } = dashboardProp;

    //편집용 대시보드 속성
    let resultObj = {
      dashboardKey: dashboardKey,
      dashboardName: dashboardName,
      editableYn: editableYn,
      shereYn: shereYn,
      rpstvYn: rpstvYn
    }
   

    return resultObj;
  },
  // cachePolicy_UNSTABLE: {
  //   // Only store the most recent set of dependencies and their values
  //   eviction: 'most-recent'
  // },
   dangerouslyAllowMutability: true
});
//////////////////////////////////////////////

////////////////대시보드 위젯///////////////////

//스크롤 관련
export const getInfiniteScrollResource = selectorFamily<any, any>({
	key : 'getInfiniteScrollResource_ds_wigt',
	get : ({apiURL, apiParam, responseJsonPathKeyNames}) => async ({ get }) => {
   
    const {rowCnt, pageNum} = apiParam;

    const createApiParameter = ()=>{
      const params = new URLSearchParams();
      params.append('rowCnt', rowCnt.toString());
      params.append('pageNum', (pageNum * rowCnt).toString());
      return params;
    }
  
		const response = await axios.post( apiURL, createApiParameter()).catch((err)=>{throw err});
    let newList:Array<any> = null;

    for(let i = 0 ;i < responseJsonPathKeyNames.length; i++){ // 임시 처리
      let keyNm = responseJsonPathKeyNames[i];
      if(i > 0){
        newList = newList[keyNm]; 
      }else{
        newList = response[keyNm]; 
      }
    }

    let resource = newList.map((item, idx)=>{
      item.id = (pageNum * rowCnt) + idx;
      return item;
    });

    return resource;
	}, 
  // cachePolicy_UNSTABLE: {
  //   // Only store the most recent set of dependencies and their values
  //   eviction: 'most-recent'
  // }

});

//지도 관련
export const getAdminRegionPowerPlantList = selectorFamily<any, any>({
	key : 'getAdminRegionPowerPlantList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const { errorStatus } = apiParam;

    let powerPlantList = [];
    let status = '0';
    if(errorStatus.normal && !errorStatus.error){ //정상만
      status = '1';
    }else if(!errorStatus.normal && errorStatus.error){ //고장만
      status = '2';
    }else if(!errorStatus.normal && !errorStatus.error){
      return powerPlantList;
    }

		const response = await axios.get('/dashboards/resources/regionPowerPlantList' + "?errCnt=" + status).catch((err)=>{throw err});
    powerPlantList = response.data.data.powerPlantList
    // let reulst = powerPlantList.slice(0, 500);
    return powerPlantList;
	}
});

//장애 이력 관련
export const getErrorLogList = selectorFamily<any, any>({
	key : 'getErrorLogList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const { pptSeq, rowCnt, pageNum, levelStatus, bjdongCd, bizId } = apiParam;
    let eventLvlCd = "";
    for(const [key, value] of Object.entries(levelStatus)){
      if(value){
        if(eventLvlCd != ""){
          eventLvlCd = eventLvlCd +","+ EventLvl[key]
        }else{
          eventLvlCd = EventLvl[key] 
        }
      }
    }
    let result = [];
    let resource = [];

    // //아무것도 없을때
    // if(eventLvlCd === ""){
    //   return result;
    // }
    const createApiParameter = ()=>{
      const params = new URLSearchParams();
      params.append('rowCnt', rowCnt.toString());
      params.append('pageNum', (pageNum * rowCnt).toString());
      params.append('eventLvlCd', eventLvlCd);
      params.append('bjdongCd', bjdongCd);
      params.append('bizId', bizId);
      if(pptSeq){
        params.append('pptSeq', pptSeq.toString());
      }
      return params;
    }
    const apiURL = '/dashboards/resources/selectErrorLogList';
		const response = await axios.post( apiURL, createApiParameter()).catch((err)=>{throw err});
    let errorLogList:Array<any> = response.data.data.errorLogList;
    let errorCountList:Array<any> = response.data.data.errorLogCount;
    
    if(errorLogList && errorLogList.length > 0){
      resource = errorLogList.map((item, index)=>{
        const { 
          eventOccurDt, 
          eventLvlCd, 
          engSrcTypeNm, 
          eventNm, 
          custNm, 
          bjdongNm, 
          eventLvl, 
          eventTypeNm,
          eventLvlNm,
          clearDt,
          eventDetail,
          custId,
          eventDuration,
          rtuSeq,
          rtuNm,
          rtuMultiNo
        } = item;

        let resouceObj = {
          id: "map_err_"+ index,
          eventOccurDt:eventOccurDt,
          eventLvlCd:eventLvlCd,
          engSrcTypeNm:engSrcTypeNm ,
          eventNm:eventNm ,
          custNm:custNm,
          bjdongNm:bjdongNm,
          eventLvl: eventLvl,
          eventTypeNm: eventTypeNm,
          eventLvlNm: eventLvlNm,
          clearDt: clearDt,
          eventDetail: eventDetail,
          custId: custId,
          eventDuration: eventDuration,
          rtuSeq: rtuSeq,
          rtuNm: rtuNm,
          rtuMultiNo: rtuMultiNo
        }

        return resouceObj;
      });
    }else{
      return result;
    }
    
    //아무것도 없을때
    if(eventLvlCd === ""){
      result.push([])
    }else{
      result.push(resource);
    }
    result.push(errorCountList);

    if(errorLogList && errorLogList.length === 0){
      result.push("noData");
    }
    return result;
	}, 

  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent'
  },
});

//지역 날씨 관련
export const getAdminRegionWeather = selector({
	key : 'getAdminRegionWeather_ds_wigt',
	get : async ({ get }) => {

		const response = await axios.get('/dashboards/resources/selectAdminRegionWeather').catch((err)=>{throw err});
    let regionWeatherList = response.data.data.regionWeatherList;
    let resource = regionWeatherList.map((regionWeather,index)=>{

      const { sky, skyNm, pty, ptyNm, tmp, reh, bjdongCd, bjdongNm } = regionWeather;

        let resouceObj = {
          sky:sky,
          skyNm:skyNm,
          pty:pty ,
          ptyNm:ptyNm ,
          tmp:tmp,
          reh:reh,
          bjdongCd:bjdongCd,
          bjdongNm:bjdongNm,
          icon:null,
          iconAlt:'날씨 아이콘',
        }

            // '/static/images/ic_rainy_s.png' //비, 빗방울, 빗방울날림
            // '/static/images/ic_snow_s.png' // 눈, 눈날림
            // '/static/images/ic_showerrain_s.png' //비/눈
            // '/static/images/ic_sun_s.png'  // 맑음
            // '/static/images/ic_cloudy_s.png' //구름많음
            // '/static/images/ic_brokenclouds_s.png' //흐림
          if(pty == 0){
            
            switch ( sky ) {  
                case '1':  //맑음
                  resouceObj.icon = '/static/images/ic_sun_s.png';
                  break;

                case '3':  //구름많음
                    resouceObj.icon = '/static/images/ic_cloudy_s.png';
                  break; 

                case '4':  //흐림
                    resouceObj.icon = '/static/images/ic_brokenclouds_s.png';
                  break; 

                default:
                  break; 
              }

          }else{

            switch ( pty ) {  
                case '1':  //비
                    resouceObj.icon = '/static/images/ic_rainy_s.png';
                  break;
                case '2':  // 비/눈
                    resouceObj.icon = '/static/images/ic_showerrain_s.png';
                    break; 
                case '3':  //눈
                    resouceObj.icon = '/static/images/ic_snow_s.png';
                    break; 
                case '4':  //소나기
                    resouceObj.icon = '/static/images/ic_rainy_s.png';
                    break; 
                case '5':  //빗방울
                    resouceObj.icon = '/static/images/ic_rainy_s.png';
                    break; 
                case '6':  //빗방울날림
                    resouceObj.icon = '/static/images/ic_rainy_s.png';
                    break; 
                case '7':  //눈날림
                    resouceObj.icon = '/static/images/ic_snow_s.png';
                    break; 
                default:
                    break; 
              }

          }

         return resouceObj;
    })

    return resource;

	}
});

//지역 날씨 상세 관련
export const getAdminRegionDetailWeather = selectorFamily<any, any>({
	key : 'getAdminRegionDetailWeather_ds_wigt',
	get : ( bjdongCd ) => async ({ get }) => {
 
    const response = await axios.get('/dashboards/resources/selectAdminRegionWeather/' + bjdongCd).catch((err)=>{throw err});
    let regionDetailWeatherList:[] = response.data.data.regionDetailWeatherList;
    // 데이터 리스트
    let dataList = [];
    // 레이블 리스트 
    let labels = [];
    // 원데이터 리스트
    let resourceList = regionDetailWeatherList.map((regionDetailWeather, index)=>{
      const { sky, skyNm, pty, ptyNm, tmp, reh, pcp, weatherDt } = regionDetailWeather;
      const weatherDtHour = new Date(weatherDt).getHours();
      
      dataList.push(tmp);
      labels.push(weatherDtHour + "시");
      
      let resouceObj = {
        sky:sky,
        skyNm:skyNm,
        pty:pty ,
        ptyNm:ptyNm ,
        tmp:tmp,
        reh:reh,
        pcp:pcp,
        weatherDt:weatherDt,
        weatherDtHour:weatherDtHour
      }

      return resouceObj;
    })

    let resource = {
      dataList:dataList,
      labels:labels,
      resourceList:resourceList
    }

    return resource;


	}

});


//발전량 추이 관련
export const getGenerationStatisticList = selectorFamily<any, any>({
	key : 'getGenerationStatisticList_ds_wigt',
	get : (apiParam) => async ({ get }) => {
    const { overallType, srcType, bjdongItem, tempBizId } = apiParam;
    let searchType = "HOUR"
    let bjdong = ""
    let bizId = tempBizId;

    if (overallType == "DAY") {
      searchType = "HOUR"
    } else {
      searchType = "DAY"
    }

    if (bjdongItem && bjdongItem.cd != DASHBOARD_BJDONG_CD_ALL) {
      bjdong = getBjdongCd(bjdongItem.pCd, bjdongItem.cd);
    }
    const url = '/dashboards/resources/selectGenerationStatistics';
		const response = await axios.get(url + "?cycleType=" + searchType + "&engSrcTypeCd=" + srcType + "&bjdongCd=" + bjdong + "&bizId=" + bizId ).catch((err)=>{throw err});
    let generationStatisticsList = response.data.data.list;
    return generationStatisticsList;
	}
});

//고객 민원 관련
export const getInqStatisticList = selectorFamily<any, any>({
	key : 'getInqStatisticList_ds_wigt',
	get : (apiParam) => async ({ get }) => {
    const { bjdongItem, bizId } = apiParam;

    let bjdong = ""
    if (bjdongItem && bjdongItem.cd != DASHBOARD_BJDONG_CD_ALL) {
      bjdong = getBjdongCd(bjdongItem.pCd, bjdongItem.cd);
    }

    const url = '/dashboards/resources/selectInqStatus';
		const response = await axios.get(url + "?bizId=" + bizId + "&bjdongCd=" + bjdong).catch((err)=>{throw err});

    let inqStatusList = response.data.data.inqStatusList;

    return inqStatusList;
	}
});

//운영현황 관련  
export const getOperationStatus = selectorFamily<any, any>({
	key : 'getOperationStatus_ds_wigt',
	get : (apiParam) => async ({ get }) => {
    const { bjdongCd, bizId } = apiParam;
    
    let url = '/dashboards/resources/selectOperationStatus';
		const response = await axios.get( url + "?bjdongCd=" + bjdongCd + "&bizId=" + bizId ).catch((err)=>{throw err});

    let regionEquipGenList:[any] = response.data.data.regionEquipGenList;
    let resource = regionEquipGenList.map((region,index)=>{
        let resouceObj = {
          id:index,
          bjdongCd:null,
          bjdongNm:null ,
          pvEquipCnt:null ,
          pvCurGen:null,
          pvEquipCapa:null,
          shEquipCnt:null,
          shCurGen:null,
          shEquipCapa:null,
          geoEquipCnt:null,
          geoCurGen:null,
          geoEquipCapa:null,
          essEquipCnt:null,
          essCurGen:null,
          essEquipCapa:null,
          windEquipCnt:null,
          windCurGen:null,
          windEquipCapa:null,
          fcEquipCnt:null,
          fcCurGen:null,
          fcEquipCapa:null,
          stEquipCnt:null,
          stCurGen:null,
          stEquipCapa:null,
          failurePvCnt:null,
          warningPvCnt:null,
          failureStCnt:null,
          warningStCnt:null,
          failureGeoCnt:null,
          warningGeoCnt:null
        }

        // 공통
        resouceObj.bjdongCd = region.bjdongCd;
        resouceObj.bjdongNm = region.bjdongNm;

        resouceObj.pvEquipCnt = region.equipPvCnt;
        resouceObj.pvCurGen = Math.round(parseFloat(region.curPvGen) / 100) / 10;
        resouceObj.pvEquipCapa = region.equipPvCapa;
        
        resouceObj.stEquipCnt = region.equipStCnt;
        resouceObj.stCurGen = Math.round(parseFloat(region.curStGen) / 100) / 10;
        resouceObj.stEquipCapa = region.equipStCapa;

        resouceObj.geoEquipCnt = region.equipGeoCnt;
        resouceObj.geoCurGen = Math.round(parseFloat(region.curGeoGen) / 100) / 10;
        resouceObj.geoEquipCapa = region.equipGeoCapa;

        resouceObj.failurePvCnt = region.failurePvCnt;
        resouceObj.warningPvCnt = region.warningPvCnt;
        resouceObj.failureStCnt = region.failureStCnt;
        resouceObj.warningStCnt = region.warningStCnt;
        resouceObj.failureGeoCnt = region.failureGeoCnt;
        resouceObj.warningGeoCnt = region.warningGeoCnt;

        return resouceObj;

    });
    return resource;
	}

});

//계통도 관련   
export const getRegionGenStatus = selectorFamily({
	key : 'getRegionGenStatus_ds_wigt',
	get : (bjdongCd) => async ({ get }) => {

    let url = 'https://mgv2v.raonfriends.link/getVtoV';
    url = url + "?month=" +  dayjs().format('YYYY-MM');
    
		const response = await axios.get(url).catch((err)=>{
      // CORS 에러 발생할 경우 기본적인 화면 표현을 위해서 더미 데이터를 넣어준다.
      return {
        data: {
          result: [
            {
              buyGrid: 0,
              buyTown: 0,
              excessGen: 0,
              gen_sum: 0,
              loc: "\ub300\uc804\uad11\uc5ed\uc2dc",
              month: "2024-10",
              sellTown: 0,
              substitutionRate: 0,
              totalBuyCost: 0,
              totalSellCost: 0,
              tradeList: [],
              usg_sum: 0
            },
            {
              buyGrid: 0,
              buyTown: 0,
              excessGen: 0,
              gen_sum: 0,
              loc: "\ubcf4\ub839\uc2dc",
              month: "2024-10",
              sellTown: 0,
              substitutionRate: 0,
              totalBuyCost: 0,
              totalSellCost: 0,
              tradeList: [],
              usg_sum: 0
            },
            {
              buyGrid: 0,
              buyTown: 0,
              excessGen: 0,
              gen_sum: 0,
              loc: "\uad70\uc0b0\uc2dc",
              month: "2024-10",
              sellTown: 0,
              substitutionRate: 0,
              totalBuyCost: 0,
              totalSellCost: 0,
              tradeList: [],
              usg_sum: 0
            }
          ],
          state: 1
        }
      }
    });

    const createResoureObject = function(region, state){

                /*
                  태양광	PV 01
                  태양열	SH 02
                  지열	GEO 03
                  풍력	WIND 04
                  연료전지	FC 06
                  ESS	ESS 07
                */    
              // 에너지별 사용량 소비량 
              let RegionEquip = function(){
                this.bjdongCd = '';
                this.bjdongNm = '';
                this.weatherIcon = '';
                
                this.totElecCurUsg = 0;
                this.totHeatCurUsg = 0;

                this.pvCurGen = 0;
                this.pvCurUsg = 0;
                this.pvCapa = 0;
                this.shCurGen = 0;
                this.shCurUsg = 0;
                this.shCapa = 0;
                this.geoCurGen = 0;
                this.geoCurUsg = 0;
                this.geoCapa = 0;
                this.essCurGen = 0;
                this.essCurUsg = 0;
                this.essCapa = 0;
              }

            let resouceObj = new RegionEquip();
            
            let checkValue = function(value){
              return value == '' || value == null ? 0 : parseInt(value);
            };

            let unitConversion = function(value){
              return  Math.round(value / 100) / 10;
            }

            
            resouceObj.bjdongNm = region.loc;
            resouceObj.weatherIcon = '';
            resouceObj.totElecCurUsg = checkValue(region.usg_sum)
            // 마을간 거래가 있었을 경우(대전광역시 <=> 군산시, 군산시 <=> 보령시, 보령시 <=> 대전광영시 값 순으로 세팅)
            resouceObj.trade = {
              buy: 0,
              sell: 0
            }
            if(state != 1 && state != 2){
              if(resouceObj.bjdongNm == '대전광역시'){
                region.tradeList.forEach((tradeItem) => {
                  if(tradeItem.loc == '군산시') {
                    resouceObj.trade.buy = (tradeItem.buyGen / 1000).toFixed(2);
                    resouceObj.trade.sell = (tradeItem.sellGen / 1000).toFixed(2);
                  }
                })
              } else if(resouceObj.bjdongNm == '군산시'){
                region.tradeList.forEach((tradeItem) => {
                  if(tradeItem.loc == '보령시') {
                    resouceObj.trade.buy = (tradeItem.buyGen / 1000).toFixed(2);
                    resouceObj.trade.sell = (tradeItem.sellGen / 1000).toFixed(2);
                  }
                })
              } else if(resouceObj.bjdongNm == '보령시'){
                region.tradeList.forEach((tradeItem) => {
                  if(tradeItem.loc == '대전광역시') {
                    resouceObj.trade.buy = (tradeItem.buyGen / 1000).toFixed(2);
                    resouceObj.trade.sell = (tradeItem.sellGen / 1000).toFixed(2);
                  }
                })
              }
            }
      return resouceObj;
    }

    // 테스트를 위해 변수 생성
    // let response = {
    //   data: {
    //     data: {
    //       result: [
    //         {
    //           "buyGrid": 0,
    //           "buyTown": 0,
    //           "excessGen": 5006733.0,
    //           "gen_sum": 17564900,
    //           "loc": "\ub300\uc804\uad11\uc5ed\uc2dc",
    //           "month": "2023-12",
    //           "sellTown": 5006733.0,
    //           "substitutionRate": 69.93,
    //           "totalBuyCost": 0,
    //           "totalSellCost": 751009950.0,
    //           "tradeList": [
    //             {
    //               "buyCost": 0,
    //               "buyGen": 0,
    //               "loc": "\uad70\uc0b0\uc2dc",
    //               "sellCost": 591955500.0,
    //               "sellGen": 3946370.0
    //             },
    //             {
    //               "buyCost": 0,
    //               "buyGen": 0,
    //               "loc": "\ubcf4\ub839\uc2dc",
    //               "sellCost": 159054450.0,
    //               "sellGen": 1060363.0
    //             }
    //           ],
    //           "usg_sum": 25116334
    //         },
    //         {
    //           "buyGrid": 0,
    //           "buyTown": 3946370.0,
    //           "excessGen": -3946370.0,
    //           "gen_sum": 19236600,
    //           "loc": "\uad70\uc0b0\uc2dc",
    //           "month": "2023-12",
    //           "sellTown": 0,
    //           "substitutionRate": 41.49,
    //           "totalBuyCost": 591955500.0,
    //           "totalSellCost": 0,
    //           "tradeList": [
    //             {
    //               "buyCost": 591955500.0,
    //               "buyGen": 3946370.0,
    //               "loc": "\ub300\uc804\uad11\uc5ed\uc2dc",
    //               "sellCost": 0,
    //               "sellGen": 0
    //             }
    //           ],
    //           "usg_sum": 46365940
    //         },
    //         {
    //           "buyGrid": 6827433.5,
    //           "buyTown": 1060363.0,
    //           "excessGen": -7887796.5,
    //           "gen_sum": 19266500,
    //           "loc": "\ubcf4\ub839\uc2dc",
    //           "month": "2023-12",
    //           "sellTown": 0,
    //           "substitutionRate": 35.48,
    //           "totalBuyCost": 1865912825.0,
    //           "totalSellCost": 0,
    //           "tradeList": [
    //             {
    //               "buyCost": 159054450.0,
    //               "buyGen": 1060363.0,
    //               "loc": "\ub300\uc804\uad11\uc5ed\uc2dc",
    //               "sellCost": 0,
    //               "sellGen": 0
    //             }
    //           ],
    //           "usg_sum": 54308593
    //         }
    //       ],
    //       state: 3
    //     }
    //   }
    // }
    let resource:any = null;

    let regionEquipGenList = response.data.result;

    //대덕구, 보령시, 군산시
    let locList = ['대전광역시', '군산시', '보령시']; 


    resource = regionEquipGenList
    .filter((region)=>{  //
      let findedIndex = locList.findIndex((ele)=>{
        return ele == region.loc;
      });
      
      return findedIndex == -1 ? false : true;
      
    })
    .map((region,index)=>{  
      return createResoureObject(region, response.data.state);
    });

    console.log('resource'+ JSON.stringify(resource))
    return resource;
	}
	// get : (bjdongCd) => async ({ get }) => {
  //   let url = 'http://ec2-13-124-57-70.ap-northeast-2.compute.amazonaws.com:5000/getVtoV?';
  //   let thisMonth = dayjs(new Date).format("YYYY-MM");
  //   url = url + "?month=" + thisMonth;
    
	// 	const response = await axios.get(url).catch((err)=>{throw err});


  //   const createResoureObject = function(region){

  //               /*
  //                 태양광	PV 01
  //                 태양열	SH 02
  //                 지열	GEO 03
  //                 풍력	WIND 04
  //                 연료전지	FC 06
  //                 ESS	ESS 07
  //               */    
  //             // 에너지별 사용량 소비량 
  //             let RegionEquip = function(){
  //               this.bjdongCd = '';
  //               this.bjdongNm = '';
  //               this.weatherIcon = '';
                
  //               this.totElecCurUsg = 0;
  //               this.totHeatCurUsg = 0;

  //               this.pvCurGen = 0;
  //               this.pvCurUsg = 0;
  //               this.pvCapa = 0;
  //               this.shCurGen = 0;
  //               this.shCurUsg = 0;
  //               this.shCapa = 0;
  //               this.geoCurGen = 0;
  //               this.geoCurUsg = 0;
  //               this.geoCapa = 0;
  //               this.essCurGen = 0;
  //               this.essCurUsg = 0;
  //               this.essCapa = 0;
  //             }

  //           let resouceObj = new RegionEquip();
            
  //           let checkValue = function(value){
  //             return value == '' || value == null ? 0 : parseInt(value);
  //           };

  //           let unitConversion = function(value){
  //             return  Math.round(value / 100) / 10;
  //           }

  //           let checkWeather = function(pty, sky){
  //                  // '/static/images/ic_rainy_s.png' //비, 빗방울, 빗방울날림
  //               // '/static/images/ic_snow_s.png' // 눈, 눈날림
  //               // '/static/images/ic_showerrain_s.png' //비/눈
  //               // '/static/images/ic_sun_s.png'  // 맑음
  //               // '/static/images/ic_cloudy_s.png' //구름많음
  //               // '/static/images/ic_brokenclouds_s.png' //흐림
  //             let resultWeatherIcon = ''
  //             if(pty == 0){
                
  //               switch ( sky ) {  
  //                   case '1':  //맑음
  //                     resultWeatherIcon = '/static/images/ic_sun_s.png';
  //                     break;

  //                   case '3':  //구름많음
  //                     resultWeatherIcon = '/static/images/ic_cloudy_s.png';
  //                     break; 

  //                   case '4':  //흐림
  //                     resultWeatherIcon = '/static/images/ic_brokenclouds_s.png';
  //                     break; 

  //                   default:
  //                     break; 
  //                 }

  //             }else{

  //               switch ( pty ) {  
  //                   case '1':  //비
  //                       resultWeatherIcon = '/static/images/ic_rainy_s.png';
  //                     break;
  //                   case '2':  // 비/눈
  //                       resultWeatherIcon = '/static/images/ic_showerrain_s.png';
  //                       break; 
  //                   case '3':  //눈
  //                       resultWeatherIcon = '/static/images/ic_snow_s.png';
  //                       break; 
  //                   case '4':  //소나기
  //                       resultWeatherIcon = '/static/images/ic_rainy_s.png';
  //                       break; 
  //                   case '5':  //빗방울
  //                       resultWeatherIcon = '/static/images/ic_rainy_s.png';
  //                       break; 
  //                   case '6':  //빗방울날림
  //                       resultWeatherIcon = '/static/images/ic_rainy_s.png';
  //                       break; 
  //                   case '7':  //눈날림
  //                       resultWeatherIcon = '/static/images/ic_snow_s.png';
  //                       break; 
  //                   default:
  //                       break; 
  //                 }
  //             }
  //             return resultWeatherIcon;
  //           }


           
            
  //           resouceObj.bjdongCd = region.bjdongCd;
  //           resouceObj.bjdongNm = region.bjdongNm;
  //           resouceObj.weatherIcon = checkWeather(region.pty, region.sky);

  //           region.regionList.map((equipData, index)=>{
  //               switch (equipData.engSrcTypeCd) {
  //                 case '01': // 태양광
  //                 resouceObj.pvCurGen = unitConversion(checkValue( equipData.curGen));
  //                 resouceObj.pvCurUsg = unitConversion(checkValue( equipData.curUsg));
  //                 resouceObj.pvCapa = equipData.moduleTotCapa;

  //                 resouceObj.totElecCurUsg =  resouceObj.totElecCurUsg + resouceObj.pvCurUsg;

  //                   break;
  //                 default:
  //                   break;
  //               }
  //           });

  //     return resouceObj;
  //   }


  //   let resource:any = null;

  //   if(bjdongCd == DASHBOARD_BJDONG_CD_ALL){
  //     let regionEquipGenList:[any] = response.data.data.regionEquipGenList;

  //     //대덕구, 보령시, 군산시
  //     let bjdongCdList = ['3023000000', '4418000000', '4513000000']; 


  //     resource = regionEquipGenList
  //     .filter((region)=>{  //
  //       let findedIndex = bjdongCdList.findIndex((ele)=>{
  //         return ele == region.bjdongCd;
  //       });
        
  //       return findedIndex == -1 ? false : true;
        
  //     })
  //     .map((region,index)=>{  
  //       return createResoureObject(region);
  //     });
  //   } else {
  //     let region:any = response.data.data.regionEquipGen;
  //     resource = createResoureObject(region);
  //   }

  //   return resource;
	// }

});


//계통도 헤더 셀렉터 관련
export const getBjdongCdMenuItem = selector({
	key : 'getBjdongCdMenuItem_ds_wigt',
	get : async ({ get }) => {
		const response = await axios.get('/dashboards/resources/selectAdminRegion').catch((err)=>{throw err});

        let responseData = response.data; //bjdongCdMenuItem;//resource.data;
        let regionList = responseData.data.regionList;

        let defaultBjdongCd = {bjdongCd: '0000000000', bjdongNm: "전체"};
        let bjdongCdList = [defaultBjdongCd];

        if(regionList != null){
            for(let i in regionList){
                let item = regionList[i];

                bjdongCdList.push({
                    bjdongCd: item.bjdongCd,
                    bjdongNm: item.bjdongNm
                })
            }
        }

    return bjdongCdList;
	}
});

//에너지 소비 대체율 관련
export const getEsrrStatus = selectorFamily<any, any>({
	key : 'getEsrrStatus_ds_wigt',
  get: ({ bjdongItem, bizId }) => async ({ get }) => {
    
    let bjdong = "";

    if (bjdongItem && bjdongItem.cd != DASHBOARD_BJDONG_CD_ALL) {
      bjdong = getBjdongCd(bjdongItem.pCd, bjdongItem.cd);
    }
    
    const bodyObj = {
			cycleTypeCd: "HOUR",
			startDt: dayjs().format('YYYYMMDD'),
      bjdongCd : bjdong,
      bizId: bizId
		}

		const data = await axios.post("/newEnergyStatList", bodyObj)
    const dataObj = data.data.data.list;

    return dataObj

    // const queryString = bjdongCd == DASHBOARD_BJDONG_CD_ALL ? "" : "?bjdongCd=" + bjdongCd

    // const url = '/dashboards/resources/selectAdminEsrrStatus' + queryString;
		// const response = await axios.get(url).catch((err)=>{throw err});
    // let resource = {
    //   curGen: 0,
    //   curUsg: 0
    // }

    // let esrrStatus = response.data.data.esrrStatus;

    // if(esrrStatus != null){
    //   resource.curGen = esrrStatus.curGen;
    //   resource.curUsg = esrrStatus.curUsg;
    // }

    // return resource;
	}
});

//에너지 미션 관련
export const getDr = selector({
	key : 'getDr_ds_wigt',
	get : async ({ get }) => {

    const url = '/dashboards/resources/selectAdminDr';
		const response = await axios.get(url).catch((err)=>{throw err});

    let dr = response.data.data.dr;
    let drPoint = response.data.data.drPoint;

    if( dr != null ){
      let drStartDt = dr.drStartDt;
      let date = new Date(drStartDt);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay() + 1;
      let hours = date.getHours();
      dr.drStartDt = month + '월 ' + day + '일 '+hours+ '시'
    }

    let resource = {
      dr:dr,
      drPoint:drPoint
    };

    return resource;
	}
});

//에너지 종합 현황 관련
export const getOverallStatus = selectorFamily<any, any>({
	key : 'getOverallStatus_ds_wigt',
  get: ({ energyType, overallType, tempOverallBjdong, tempBizId }) => async ({ get }) => {
 
    let searchType = "HOUR"
    let startDt = dayjs().format('YYYYMMDD')
    let bjdong = ""
    let bjdongItem = tempOverallBjdong;
    let bizId = tempBizId;

    if (overallType == "DAY") {
      searchType = "HOUR"
      startDt = dayjs().format('YYYYMMDD')
    } else {
      searchType = "DAY"
      startDt = dayjs().format('YYYYMM01')
    }

    if (bjdongItem && bjdongItem.cd != DASHBOARD_BJDONG_CD_ALL) {
      bjdong = getBjdongCd(bjdongItem.pCd, bjdongItem.cd);
    }
    
    const bodyObj = {
			cycleTypeCd: searchType,
			startDt: startDt,
      endDt: dayjs().format('YYYYMM') + dayjs().daysInMonth(),
      bjdongCd : bjdong,
      bizId: bizId
    }
 
		const data = await axios.post("/newEnergyStatList", bodyObj)
    let dataObj :any = data.data.data.list;
    dataObj.searchType = searchType

    return dataObj

  //   const createQueryString = function(){
      
  //     const cycleTypeParam = 'cycleType=' + overallType;
  //     const bjdongCdParam = 'bjdongCd=' + bjdongCd;
      
  //     const paramList = [];
      
  //     paramList.push(cycleTypeParam);
  //     if(bjdongCd != DASHBOARD_BJDONG_CD_ALL){ //전체가 아닌경우
  //       paramList.push(bjdongCdParam);
  //     }

  //     let queryString = "";
  //     if(paramList.length > 0){
  //       queryString = "?" + paramList.join("&");
  //     }
      
  //     return queryString;
  //   }
  //   console.log(overallType, bjdongCd)
  //   const url = '/dashboards/resources/selectAdminOverallStatus' + createQueryString();
	// 	const response = await axios.get(url).catch((err)=>{throw err});

  //   let overallStatus = response.data.data.overallStatus;
  //   let { pptCnt, pvTotCapa, equipCnt, curGen, curUsg, generationStatisticList } = overallStatus;

  //   let createLabelList = function(type){
  //     let resultData = [];
  //     let unit = "";

  //     switch (type) {

  //       case 'DAY':
  //         unit = '시';
  //         resultData = Array.from({length: 24}, (v, i) => i + unit);
  //         break;

  //       case 'MON':
  //         let today = new Date();
  //         let year = today.getFullYear(); // 년도
  //         let month = today.getMonth() + 1;  // 월
  //         let monthDayCount = new Date(year,month,0).getDate(); //현재 달의 일수
  //         unit = '일';

  //         resultData =  Array.from({length: monthDayCount}, (v, i) => (i + 1) + unit);

  //         break;
  

  //       default:
  //         break;
  //     }
 
  //     return resultData;
  //   }

  //   let resource = {
  //     pptCnt: pptCnt,               //개소수
  //     pvTotCapa : pvTotCapa,        //설비 용량
  //     curGen : curGen,              //발전량
  //     curUsg : curUsg,              //소비량
  //     realUsg : (curGen - curUsg),  //실제사용량
  //     // labelList:[],                 //그래프 lable 리스트
  //     labelList: createLabelList(overallType),                 //그래프 lable 리스트
  //     genDataList :[],              //그래프 발전량 데이터 리스트
  //     usgDataList :[],              //그래프 소비량 데이터 리스트
  //     replaceRateDataList : []      //그래프 대체율 데이터 리스트
  //   }

  //   for(let i in generationStatisticList){
  //     let item = generationStatisticList[i];
  //     let { curGen, curUsg, stDt} = item;

  //     // resource.labelList.push(stDt.slice(-2));
  //     resource.genDataList.push(curGen == null ? 0 : Math.round(curGen / 100) / 10);
  //     resource.usgDataList.push(curUsg == null ? 0 : Math.round(curUsg / 100) / 10);
  //     resource.replaceRateDataList.push(curUsg == 0? 0: Math.round( ((curGen / curUsg) * 100) * 10) / 10);
  //   }

  //   return resource;
	}
});

//사업 리스트
export const getBusinessList = selectorFamily<any, any>({
	key : 'getBusinessList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const params = new URLSearchParams();

    const apiURL = '/businessComboList';
		const response = await axios.post( apiURL, params).catch((err)=>{throw err});

    let bizList:Array<any> = response.data;

    let result = [];
    if(bizList.length > 0){
      result = bizList.map((item, index)=>{
        const { bizId, bizNm } = item;

        let resouceObj = {
          bizId:bizId,
          bizNm:bizNm,
        }
        return resouceObj;
      });
    }
    return result;
	}, 
});

//지역 리스트
export const getRegionList = selectorFamily<any, any>({
	key : 'getRegionList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const response = await axios.get('/bizBjdongComboList');

    let regionList:Array<any> = response.data;

    let result = [];
    if(regionList.length > 0){
      result = regionList.map((data)=>{
        const {cdNm, pCd, cd, cdCnt} = data;

        let resourceObj = {
          cdNm: cdNm,
          pCd: pCd,
          cd: cd,
          cdCnt: cdCnt
        }
        return resourceObj;
      })
    }
		return result;
	}
});


//아크 위험도
export const getArkRiskList = selectorFamily<any, any>({
	key : 'getArkRiskList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const params = new URLSearchParams();
    
    let bjdong = ""
    if (apiParam.bjdongItem && apiParam.bjdongItem.cd != DASHBOARD_BJDONG_CD_ALL) {
      bjdong = getBjdongCd(apiParam.bjdongItem.pCd, apiParam.bjdongItem.cd);
    }
    params.append('bizId', apiParam.bizId);
    params.append('bjdongCd', bjdong);

    const apiURL = '/dashboards/resources/getArkRiskList';
		const response = await axios.post( apiURL, params ).catch((err)=>{throw err});

    let riskList:Array<any> = response.data.data.arkRiskList;
    let result = [];
    if(riskList.length > 0){
      riskList.map((data)=>{
        let object = {
          dng: data.area,
          riskCnt: data.riskCnt
        }
        result.push(object);
      })
    }

    return result;
	}, 
});

//공지사항 위젯
export const getNoticeList = selectorFamily<any, any>({
	key : 'getNoticeList_ds_wigt',
	get : (apiParam) =>  async ({ get }) => {
    const params = new URLSearchParams();
    params.append('bizId', apiParam.bizId);
    const apiURL = '/dashboards/resources/getNoticeList';
		const response = await axios.post( apiURL, params ).catch((err)=>{throw err});

    let noticeList:Array<any> = response.data.data.noticeList;
    let result = [];
    if(noticeList.length > 0){
      noticeList.map((data)=>{
        let object = {
          bdSeq: data.bdSeq,
          bizId: data.bizId,
          bizNm: data.bizNm,
          notiTitle: data.notiTitle,
          regDt: data.regDt,
          regId: data.regId,
          notiConts: data.notiConts,
          notiPopYn: data.notiPopYn,
          notiFromDt: data.notiFromDt,
          notiToDt: data.notiToDt,
          fileNm: data.fileNm,
          fileExts: data.fileExts,
          attacSeq: data.attacSeq,
          id: data.bdSeq
        }
        result.push(object);
      })
    }

    return result;
	}, 
});
//////////////////////////////////////////////

